* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}


/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  background-color: #121212;
  transition:  0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
    width: 10%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  font-size: 30px;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 15px;
  /* font-size: 18px; */
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}

span.navbar-text {
    display: flex;
    align-items: center;
}

.social-icon {
    display: inline-block;
    margin-left: 20px;
}

.social-icon a {
    width: 60px;
    height: 60px;
    background: #fff;
    display: inline-flex;
    border-radius: 50%;
    margin-right: 20px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
    content: "";
    width: 60px;
    height: 60px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
    transform: scale(1.5);
}

.social-icon a img {
	width: 70%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: hsl(0, 94%, 41%);
  border: 1px solid #6f6565;
  padding: 18px 34px;
  font-size: 30px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  background-color: #fff;
  color: #121212;
}

.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2.5px solid #fff;
  transition: all 300ms linear;
  top: -2px;
} 

nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2.5px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  background-color: #fff;
	top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

nav.navbar.scrolled {
  background-color: #1c1b1b;
}

/**********Banner**********/
.banner {
  background-image: url('./img/ps1.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  font-size: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner .notShowGreeting {
  visibility: hidden;
}

.banner .container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner .greeting {
  width: 60%;
  /* margin-left: 15%; */
  margin-top: -10%;
  background: black;
  
}

.banner .greeting h1{
  text-align: center;
  font-weight: 700;
  font-size: 18%;
}

.banner .greeting h2{
  text-align: center;
  font-weight: 700;
  font-size: 10%;
}

@media (max-width: 1000px) {
  .banner .greeting h1{
    font-size: 10%;
  }
  
  .banner .greeting h2{
    font-size: 7%;
  }
}

@media (max-width: 770px) {
  .banner {
    height: 80vh;
  }
}

@media (max-width: 565px) {
  .banner {
    height: 70vh;
  }

  .banner .greeting h1{
    font-size: 7%;
  }
  
  .banner .greeting h2{
    font-size: 5%;
  }
}

@media (max-width: 430px) {
  .banner .greeting h1{
    font-size: 6%;
  }
  
  .banner .greeting h2{
    font-size: 5%;
  }
}

@media (max-width: 375px) {
  .banner .greeting h1{
    font-size: 5%;
  }
  
  .banner .greeting h2{
    font-size: 5%;
  }
}

/**********AboutMe**************/
.aboutMe {
  width: 100%;
  /* height: 80vh; */
  padding-top: 100px;
  text-align: center;
}

.aboutMe .aboutMe_tit {
  margin-bottom: 5%;
}

.aboutMe .aboutMe_tit h1 {
  font-size: 90px;
  font-style: oblique;
}

.aboutMe h2 {
  font-size: 50px;
}

.aboutMe p {
  color: #bbb6b6;
  font-size: 30px;
}

.aboutMe .greeting {
  font-size: 50px;
  font-weight: 400;
}

@media (max-width: 900px) {
  .aboutMe {
    height: 70vh;
  }

  .aboutMe .aboutMe_tit h1 {
    font-size: 70px;
  }
  
  .aboutMe h2 {
    font-size: 40px;
  }
  
  .aboutMe p {
    font-size: 20px;
  }

  .aboutMe .greeting {
    font-size: 40px;
    font-weight: 400;
  }
}

@media (max-width: 500px) {
  .aboutMe {
    height: 70vh;
  }
  .aboutMe .aboutMe_tit h1 {
    font-size: 50px;
  }
  
  .aboutMe h2 {
    font-size: 30px;
  }
  
  .aboutMe p {
    font-size: 10px;
  }

  .aboutMe .greeting {
    font-size: 20px;
    font-weight: 400;
  }
}

/**********ShowHeat*************/

.showHeat {
  width: 100%;
  /* height: 145vh; */
  padding-top: 100px;
}

.showHeat .heat_tit {
  text-align: center;
  margin-bottom: 20px;
}

.showHeat .heat_tit h1 {
  font-style: italic;
  font-size: 70px;
  font-weight: 700;
}

.heat_slider {
  width: 80%;
  height: 40%;
  margin: 0 auto;
  position: relative;
}

.heat_slider .react-multi-carousel-track {
  height: 100%;
}

.heat_slider .heat_img {
  width: 80%;
  height: 80%;
  margin: 0 auto 30px auto;
}

.heat_slider .heat_img h1{
  text-align: center;
  font-size: 50px;
}

.heat_slider .heat_img h2{
  text-align: center;
}

.heat_slider .heat_img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lunch_tit {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.lunch_tit h1{
  font-style: italic;
  font-size: 70px;
  font-weight: 700;
}

@media (max-width: 770px) {
  .showHeat {
    height: 160vh;
  }

  .showHeat .heat_tit h1 {
    font-size: 65px;
  }

  .showHeat .lunch_tit h1 {
    font-size: 65px;
  }

  .heat_slider .heat_img h1{
    font-size: 30px;
  }
}

@media (max-width: 500px) {
  .showHeat {
    height: 170vh;
  }

  .showHeat .heat_tit h1 {
    font-size: 40px;
  }

  .showHeat .lunch_tit h1 {
    font-size: 40px;
  }

  .heat_slider .heat_img h1{
    font-size: 25px;
  }
}

/************Menu*************/
.menu {
  width: 100%;
  /* height: 100vh; */
  text-align: center;
  margin-top: 100px;
}

.menu h1 {
  font-size: 90px;
  font-style: oblique;
}

.menu .menu-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.menu-box {
  width: 45%;
}

@media (max-width: 1700px) {
  .menu {
    /* height: 80vh; */
    margin-top: 100px;
  }
}

@media (max-width: 1024px) {
  .menu {
    /* height: 100vh; */
    margin-top: 100px;
  }

  .menu .menu-container {
    display: block;
    padding-left: 5%;
    padding-right: 5%;
  }

  .menu-box {
    margin-top: 20px;
    width: 100%;
    height: 40vh;
  }

  .menu-box img{
    width: 100%;
    height: 100%;
  }
}

:root {
  --menu1-dis: none;
  --menu2-dis: none;
}

.menu1-preview {
  display: var(--menu1-dis);
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
}
.menu1-preview img {
  z-index: 100;
  width: 90%;
  height: auto;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.menu2-preview {
  display: var(--menu2-dis);
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
}
.menu2-preview img {
  z-index: 100;
  width: 90%;
  height: auto;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

@media (max-width: 900px) {
  .menu h1 {
    font-size: 70px;
  }
}

@media (max-width: 500px) {
  .menu h1 {
    font-size: 50px;
  }
}

/**********Footer**************/
.footer {
  width: 100%;
  /* height: 10vh; */
  margin-top: 50px;
  display: flex;
  align-items: center;
  background-color: #1c1b1b;
}

.footer_box1 {
  width: 50%;
  padding-left: 30px;
}

.footer .social-icon {
  width: 50%;
  padding-right: 30px;
}

.footer .social-icon a {
  float: right;
}

@media (max-width: 500px) {
  .footer {
    display: inline-block;
    font-size: 12px;
  }

  .footer_box1 {
    width: 100%;
    padding-left: 0px;
    margin-bottom: 5px;
  }

  .footer .social-icon {
    width: 100%;
    padding-right: 0;
  }

  .footer .social-icon a{
    float: left;
    width: 30px;
    height: 30px;
  } 
}